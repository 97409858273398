import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const StripeService = {
  getStripePromise: () => stripePromise,
  
  redirectToCheckout: async (priceId) => {
    try {
      const stripe = await stripePromise;
      
      if (!stripe) {
        throw new Error('Stripe failed to load');
      }

      // Get event details for metadata
      const eventDetails = JSON.parse(localStorage.getItem('eventDetails') || '{}');
      const recordId = eventDetails.recordId;

      if (!recordId) {
        throw new Error('No record ID found');
      }

      // Store recordId in sessionStorage instead of URL
      sessionStorage.setItem('stripe_record_id', recordId);

      const checkoutOptions = {
        lineItems: [
          {
            price: priceId,
            quantity: 1,
          },
        ],
        mode: 'payment',
        // Remove recordId from URL
        successUrl: `${window.location.origin}/success`,
        cancelUrl: `${window.location.origin}/payment`,
        billingAddressCollection: 'auto',
        submitType: 'pay',
      };

      const { error } = await stripe.redirectToCheckout(checkoutOptions);

      if (error) {
        throw error;
      }
    } catch (error) {
      console.error('Payment error:', error);
      throw error;
    }
  }
};