import dayjs from 'dayjs';
import Airtable from 'airtable';
import logger from './logger';

// Environment Variables Configuration
const AIRTABLE_BASE_ID = process.env.REACT_APP_AIRTABLE_BASE_ID;
const AIRTABLE_TABLE_NAME = process.env.REACT_APP_AIRTABLE_TABLE_NAME;
const AIRTABLE_TOKEN = process.env.REACT_APP_AIRTABLE_TOKEN;

// Validate environment variables on service initialization
if (!AIRTABLE_BASE_ID || !AIRTABLE_TABLE_NAME || !AIRTABLE_TOKEN) {
  logger.error('Missing required environment variables', {
    hasBaseId: !!AIRTABLE_BASE_ID,
    hasTableName: !!AIRTABLE_TABLE_NAME,
    hasToken: !!AIRTABLE_TOKEN
  });
  throw new Error('Missing required Airtable configuration');
}

const base = new Airtable({ apiKey: AIRTABLE_TOKEN }).base(AIRTABLE_BASE_ID);

export const updatePaymentStatus = async (recordId) => {
  try {
    logger.info('Updating payment status for record', { recordId });
    
    const table = base(AIRTABLE_TABLE_NAME);
    
    const updatedRecord = await table.update([{
      id: recordId,
      fields: {
        paymentStatus: 'Paid'
      }
    }]);

    // Use logger instead of console.log
    logger.success('Payment status updated', updatedRecord);
    return updatedRecord;
  } catch (error) {
    logger.error('Error updating payment status', error);
    throw error;
  }
};

const airtableService = {
  async submitEvent(eventDetails) {
    try {
      logger.info('Starting event submission process');

      // Transform event details into Airtable fields format
      const fields = {
        "firstName": eventDetails.contact?.firstName || '',
        "lastName": eventDetails.contact?.lastName || '',
        "occasion": eventDetails.setScene?.occasion || '',
        "activity": eventDetails.setScene?.activity || '',
        "guestCount": eventDetails.setScene?.guestCount || '',
        "budget": eventDetails.setScene?.budget || '',
        "date": eventDetails.setScene?.date ? dayjs(eventDetails.setScene.date).format('YYYY-MM-DD') : undefined,
        "startTime": eventDetails.setScene?.startTime ? 
          dayjs(`2024-01-01T${eventDetails.setScene.startTime}`).format('h:mm A') : undefined,
        "duration": eventDetails.setScene?.duration || '',
        "dreamDescription": eventDetails.dream || '',
        "email": eventDetails.contact?.email || '',
        "phone": eventDetails.contact?.phone?.replace(/[^\d]/g, '') || '',
        "inspirationLink": eventDetails.inspiration?.inspirationLink || '',
        "InspirationUploadPhoto": eventDetails.inspiration?.blobUrl || '',
        "foodOption": eventDetails?.setScene?.foodAndBev?.foodOption || '',
        "drinkOption": eventDetails?.setScene?.foodAndBev?.drinkOption || '',
        "paymentStatus": 'Pending'
      };

      const cleanedFields = Object.fromEntries(
        Object.entries(fields).filter(([_, value]) => value !== undefined)
      );

      logger.info('Prepared fields for submission');

      const formattedData = { fields: cleanedFields };

      const response = await fetch(
        `https://api.airtable.com/v0/${AIRTABLE_BASE_ID}/${AIRTABLE_TABLE_NAME}`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${AIRTABLE_TOKEN}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            records: [formattedData]
          })
        }
      );

      if (!response.ok) {
        throw new Error(`Airtable API error: ${response.status}`);
      }

      const result = await response.json();
      
      logger.success('Submission successful', result);
      
      return {
        ...result,
        recordId: result.records?.[0]?.fields?.ID || result.records?.[0]?.id
      };
    } catch (error) {
      logger.error('Submission error', error);
      throw error;
    }
  }
};

export default airtableService; 