import React, { useState, useEffect, useCallback } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';
import { motion } from 'framer-motion';

// Define group size tiers and their base multipliers
const GROUP_TIERS = {
  tier1: { max: 10, multiplier: 1.2, name: '10' },
  tier2: { max: 20, multiplier: 1.1, name: '20' },
  tier3: { max: 30, multiplier: 1.0, name: '30' },
  tier4: { max: 40, multiplier: 0.95, name: '40' },
  tier5: { max: 50, multiplier: 0.9, name: '50' },
  tier6: { max: 75, multiplier: 0.85, name: '75' },
  tier7: { max: 100, multiplier: 0.8, name: '100' },
  tier8: { max: 150, multiplier: 0.75, name: '150' },
  tier9: { max: 200, multiplier: 0.7, name: '200' },
  tier10: { max: 500, multiplier: 0.65, name: '500' }
};

// Define the exact pricing matrix from the table
const PRICING_MATRIX = {
  '10': {
    2: 350,
    3: 450,
    4: 500,
    5: 550,
    6: 600,
    7: 650,
    8: 700
  },
  '20': {
    2: 1000,
    3: 1250,
    4: 1350,
    5: 1500,
    6: 1650,
    7: 1750,
    8: 1900
  },
  '30': {
    2: 1750,
    3: 2200,
    4: 2375,
    5: 2625,
    6: 2900,
    7: 3075,
    8: 3325
  },
  '40': {
    2: 2250,
    3: 2800,
    4: 3025,
    5: 3350,
    6: 3700,
    7: 3925,
    8: 4250
  },
  '50': {
    2: 2800,
    3: 3500,
    4: 3780,
    5: 4200,
    6: 4620,
    7: 4900,
    8: 5320
  },
  '75': {
    2: 3850,
    3: 4800,
    4: 5200,
    5: 5775,
    6: 6350,
    7: 6750,
    8: 7325
  },
  '100': {
    2: 5250,
    3: 6550,
    4: 7100,
    5: 7875,
    6: 8650,
    7: 9200,
    8: 9975
  },
  '150': {
    2: 7000,
    3: 8750,
    4: 9450,
    5: 10500,
    6: 11550,
    7: 12250,
    8: 13300
  },
  '200': {
    2: 9450,
    3: 11800,
    4: 12750,
    5: 14175,
    6: 15600,
    7: 16550,
    8: 17975
  },
  '500': {
    2: 12600,
    3: 15750,
    4: 17000,
    5: 18900,
    6: 20800,
    7: 22050,
    8: 23950
  }
};

// Define duration options consistently
const DURATION_OPTIONS = [
  "2 hours",
  "3 hours",
  "4 hours",
  "5 hours",
  "6 hours",
  "7 hours",
  "8 hours"
];

// New simplified multipliers
const MULTIPLIERS = {
  // Venue type multipliers (upscale is baseline)
  venueType: {
    casual: 0.85,     // Was 0.85 - increased to reduce the gap with upscale
    upscale: 0.95,    // Was 1.0 - reduced by 5%
    luxury: 1.25      // Was 1.3 - reduced by 5%
  },
  
  // Day multipliers
  eventDay: {
    weekday: 0.95,    // Was 1.0 - reduced by 5%
    weekend: 1.20     // Was 1.25 - reduced by 5%
  },
  
  // Location multipliers
  location: {
    manhattan: 1.05,  // Was 1.1 - reduced by 5%
    brooklyn: 0.95,   // Was 1.0 - reduced by 5%
    queens: 0.85      // Was 0.9 - reduced by 5%
  },
  
  // Food tier costs (per person)
  foodTier: {
    none: 0,
    appetizers: 24,   // Was 25 - reduced by 5%
    buffet: 43,       // Was 45 - reduced by 5%
    plated: 62        // Was 65 - reduced by 5%
  },
  
  // Bar package costs (per person per hour)
  barPackage: {
    noBar: 0,
    limited: 19,      // Was 20 - reduced by 5%
    standard: 28.5,   // Was 30 - reduced by 5%
    premium: 38       // Was 40 - reduced by 5%
  }
};

const VENUE_ONLY_PRICING = {
  '10': {
    2: 200,
    3: 250,
    4: 300,
    5: 350,
    6: 400,
    7: 450,
    8: 500
  },
  '20': {
    2: 600,
    3: 750,
    4: 900,
    5: 1050,
    6: 1200,
    7: 1350,
    8: 1500
  },
  '30': {
    2: 1000,
    3: 1250,
    4: 1500,
    5: 1750,
    6: 2000,
    7: 2250,
    8: 2500
  },
  '40': {
    2: 1200,
    3: 1500,
    4: 1800,
    5: 2100,
    6: 2400,
    7: 2700,
    8: 3000
  },
  '50': {
    2: 1500,
    3: 1875,
    4: 2250,
    5: 2625,
    6: 3000,
    7: 3375,
    8: 3750
  },
  '75': {
    2: 2000,
    3: 2500,
    4: 3000,
    5: 3500,
    6: 4000,
    7: 4500,
    8: 5000
  },
  '100': {
    2: 2500,
    3: 3125,
    4: 3750,
    5: 4375,
    6: 5000,
    7: 5625,
    8: 6250
  },
  '150': {
    2: 3500,
    3: 4375,
    4: 5250,
    5: 6125,
    6: 7000,
    7: 7875,
    8: 8750
  },
  '200': {
    2: 4500,
    3: 5625,
    4: 6750,
    5: 7875,
    6: 9000,
    7: 10125,
    8: 11250
  },
  '500': {
    2: 6000,
    3: 7500,
    4: 9000,
    5: 10500,
    6: 12000,
    7: 13500,
    8: 15000
  }
};

// Update tiered venue fee structure
const VENUE_FEE_TIERS = {
  tier1: { max: 5000, rate: 0.05 },      // 5.0% for base costs up to $5,000
  tier2: { max: 10000, rate: 0.045 },    // 4.5% for base costs $5,001-$10,000
  tier3: { max: 15000, rate: 0.04 },     // 4.0% for base costs $10,001-$15,000
  tier4: { max: 20000, rate: 0.035 },    // 3.5% for base costs $15,001-$20,000
  tier5: { max: Infinity, rate: 0.03 }   // 3.0% for base costs $20,001+
};

// Helper function to get the correct guest range key
const getGuestRangeKey = (guestCount) => {
  // Convert string to number if needed
  const count = Number(guestCount);
  
  // Validate input
  if (isNaN(count) || count < 1) return null;
  
  // Check ranges in order
  if (count <= 10) return '10';
  if (count <= 20) return '20';
  if (count <= 30) return '30';
  if (count <= 40) return '40';
  if (count <= 50) return '50';
  if (count <= 75) return '75';
  if (count <= 100) return '100';
  if (count <= 150) return '150';
  if (count <= 200) return '200';
  return '500';
};

// First, ensure VenueStyleSelector is properly defined and exported
const VenueStyleSelector = ({ formData, handleInputChange }) => (
  <div className="p-1">
    <div className="text-gray-600 mb-2">Venue Style</div>
    <div className="grid grid-cols-3 gap-3">
      {[
        { name: 'Casual', value: 'casual' },
        { name: 'Upscale', value: 'upscale' },
        { name: 'Luxury', value: 'luxury' }
      ].map((style) => (
        <div 
          key={style.value}
          onClick={() => handleInputChange('venueType', style.value)}
          className={`p-3 rounded-lg text-center transition-all duration-300 hover:scale-105 cursor-pointer
            ${formData.venueType === style.value 
              ? 'bg-gradient-to-br from-purple-100 to-pink-100 border-2 border-purple-300 shadow-md' 
              : 'bg-gray-50 border-2 border-gray-200 hover:border-gray-300'}`}
        >
          <div className="text-gray-800 text-sm font-medium">
            {style.name}
          </div>
        </div>
      ))}
    </div>
  </div>
);

// Update scaling factors to match F&B pricing matrix ratios
const DURATION_SCALING = {
  2: 1.0,    // Base rate (matches F&B matrix)
  3: 0.95,   // ~5% decrease per hour
  4: 0.90,
  5: 0.85,
  6: 0.80,
  7: 0.75,
  8: 0.70    // Longest duration gets best rate
};

// New more gradual scaling for bar packages
const BAR_SCALING = {
  10: 1.0,    // Base rate
  20: 0.98,   // Only 2% reduction
  30: 0.97,
  40: 0.96,
  50: 0.95,   // 5% reduction at 50 people
  75: 0.94,
  100: 0.93,  // Only 7% reduction at 100 people
  150: 0.92,
  200: 0.91,  // Only 9% reduction at 200 people
  500: 0.90   // Maximum 10% reduction even at 500 people
};

// Keep original guest count scaling for venue costs
const GUEST_COUNT_SCALING = {
  10: 1.2,    // Original venue scaling
  20: 1.1,
  30: 1.0,    // Base rate
  40: 0.95,
  50: 0.9,
  75: 0.85,
  100: 0.8,
  150: 0.75,
  200: 0.7,
  500: 0.65
};

// Add this styled switch component
const ModernSwitch = ({ checked, onChange, label }) => (
  <div className="flex items-center gap-2">
    <div 
      className={`relative w-12 h-6 rounded-full transition-colors duration-200 ease-in-out cursor-pointer
        ${checked ? 'bg-purple-600' : 'bg-gray-200'}`}
      onClick={() => onChange(!checked)}
    >
      <div 
        className={`absolute left-0.5 top-0.5 w-5 h-5 bg-white rounded-full shadow-sm 
          transition-transform duration-200 ease-in-out
          ${checked ? 'transform translate-x-6' : 'transform translate-x-0'}`}
      />
    </div>
    <span className="text-gray-900 font-medium">{label}</span>
  </div>
);

// Move roundTo25 to the top, before any components that use it
const roundTo25 = (num) => {
  return Math.ceil(num / 25) * 25;
};

// Then the smart suggestions code
const getSavingsText = (savings) => {
  if (!savings || savings < 100) return null;
  
  if (savings >= 5000) {
    return '$$$'; // Large savings (over $5,000)
  } else if (savings >= 1000) {
    return '$$';  // Medium savings ($1,000 - $4,999)
  } else {
    return '$';   // Small savings ($100 - $999)
  }
};

const calculateSavings = (currentCost, savingsPercentage) => {
  const savings = currentCost * savingsPercentage;
  console.log(`Current cost: ${currentCost}, Savings %: ${savingsPercentage}, Amount: ${savings}`);
  return savings;
};

const getSmartSuggestion = (formData, costs) => {
  if (!formData.numGuests || !formData.duration || !formData.eventDay || !formData.location) {
    return [];
  }

  const suggestions = [];
  
  // Time-based suggestions
  if (formData.eventDay === 'weekend') {
    const savings = calculateSavings(costs.total, 0.35);
    const savingsText = getSavingsText(savings);
    if (savingsText) {
      suggestions.push({
        type: 'scheduling',
        icon: '📅',
        text: `Save ${savingsText} by switching to a weekday`,
        action: {
          label: 'Switch to Weekday',
          changes: {
            eventDay: 'weekday'
          }
        }
      });
    }
  }

  // Location suggestions
  if (formData.location === 'manhattan') {
    const savings = calculateSavings(costs.total, 0.3);
    const savingsText = getSavingsText(savings);
    if (savingsText) {
      suggestions.push({
        type: 'location',
        icon: '📍',
        text: `Save ${savingsText} by switching to Brooklyn`,
        action: {
          label: 'Switch to Brooklyn',
          changes: {
            location: 'brooklyn'
          }
        }
      });
    }
  }

  // Venue style cascade
  if (formData.venueType === 'luxury') {
    const savings = calculateSavings(costs.total, 0.45);
    const savingsText = getSavingsText(savings);
    if (savingsText) {
      suggestions.push({
        type: 'venue',
        icon: '✨',
        text: `Save ${savingsText} by choosing an upscale venue`,
        action: {
          label: 'Switch to Upscale',
          changes: {
            venueType: 'upscale'
          }
        }
      });
    }
  }
  if (formData.venueType === 'upscale' || formData.venueType === 'luxury') {
    const savings = calculateSavings(costs.total, 0.35);
    const savingsText = getSavingsText(savings);
    if (savingsText) {
      suggestions.push({
        type: 'venue',
        icon: '🏠',
        text: `Save ${savingsText} by choosing a casual venue`,
        action: {
          label: 'Switch to Casual',
          changes: {
            venueType: 'casual'
          }
        }
      });
    }
  }

  // Duration suggestions
  if (parseInt(formData.duration) >= 5) {
    const hourlyRate = (costs.total / parseInt(formData.duration));
    const savingsText = getSavingsText(hourlyRate);
    if (savingsText) {
      suggestions.push({
        type: 'duration',
        icon: '⏰',
        text: `Save ${savingsText} by reducing duration by 1 hour`,
        action: {
          label: 'Reduce Duration',
          changes: {
            duration: (parseInt(formData.duration) - 1).toString()
          }
        }
      });
    }
  }

  // Food & Beverage suggestions
  if (!formData.venueRentalOnly) {
    // Bar service suggestions
    if (formData.barPricingType === 'openBar') {
      const savings = calculateSavings(costs.barCost, 0.3);
      const savingsText = getSavingsText(savings);
      if (savingsText) {
        suggestions.push({
          type: 'service',
          icon: '🍷',
          text: `Save ${savingsText} by switching to bar minimum`,
          action: {
            label: 'Switch to Bar Minimum',
            changes: {
              barPricingType: 'barMinimum'
            }
          }
        });
      }

      // Bar quality cascade for open bar
      if (formData.liquorQuality === 'premium') {
        const savings = calculateSavings(costs.barCost, 0.4);
        const savingsText = getSavingsText(savings);
        if (savingsText) {
          suggestions.push({
            type: 'bar',
            icon: '🥂',
            text: `Save ${savingsText} by switching to standard bar package`,
            action: {
              label: 'Switch to Standard',
              changes: {
                liquorQuality: 'standard'
              }
            }
          });
        }
      }
      if (formData.liquorQuality === 'standard' || formData.liquorQuality === 'premium') {
        const savings = calculateSavings(costs.barCost, 0.3);
        const savingsText = getSavingsText(savings);
        if (savingsText) {
          suggestions.push({
            type: 'bar',
            icon: '🍺',
            text: `Save ${savingsText} by switching to limited bar package`,
            action: {
              label: 'Switch to Limited',
              changes: {
                liquorQuality: 'limited'
              }
            }
          });
        }
      }
    }

    // Food service cascade
    if (formData.foodTier === 'plated') {
      const savings = calculateSavings(costs.foodCost, 0.3);
      const savingsText = getSavingsText(savings);
      if (savingsText) {
        suggestions.push({
          type: 'food',
          icon: '🍽️',
          text: `Save ${savingsText} by switching to buffet service`,
          action: {
            label: 'Switch to Buffet',
            changes: {
              foodTier: 'buffet'
            }
          }
        });
      }
    }
    if (formData.foodTier === 'buffet' || formData.foodTier === 'plated') {
      const savings = calculateSavings(costs.foodCost, 0.4);
      const savingsText = getSavingsText(savings);
      if (savingsText) {
        suggestions.push({
          type: 'food',
          icon: '🥗',
          text: `Save ${savingsText} by switching to appetizers only`,
          action: {
            label: 'Switch to Appetizers',
            changes: {
              foodTier: 'appetizers'
            }
          }
        });
      }
    }
  }

  // Venue Rental suggestion if using full service
  if (!formData.venueRentalOnly && costs.foodCost + costs.barCost > 3000) {
    const savings = calculateSavings(costs.foodCost + costs.barCost, 0.4);
    const savingsText = getSavingsText(savings);
    if (savingsText) {
      suggestions.push({
        type: 'service',
        icon: '🏢',
        text: `Save ${savingsText} by switching to venue rental only`,
        action: {
          label: 'Switch to Venue Only',
          changes: {
            venueRentalOnly: true
          }
        }
      });
    }
  }

  return suggestions;
};

// Update the SmartSuggestions component
const SmartSuggestions = ({ formData, costs, handleInputChange }) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const suggestions = getSmartSuggestion(formData, costs);
  
  if (suggestions.length === 0) return null;

  return (
    <div className="mt-4">
      <button 
        onClick={() => setShowSuggestions(!showSuggestions)}
        className="w-full flex items-center justify-between p-3 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-50"
      >
        <span className="flex items-center gap-2">
          <span>💡</span>
          Smart Suggestions ({suggestions.length})
        </span>
        <span className="text-gray-500">
          {showSuggestions ? '↑' : '↓'}
        </span>
      </button>

      {showSuggestions && (
        <div className="mt-2 space-y-2">
          {suggestions.map((suggestion, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="group p-3 bg-white rounded-lg border border-gray-200 hover:shadow-md transition-all duration-300"
            >
              <div className="flex items-start gap-3">
                <span className="text-lg">{suggestion.icon}</span>
                <div className="flex-1">
                  <p className="text-sm text-gray-700">{suggestion.text}</p>
                  <button
                    onClick={() => {
                      Object.entries(suggestion.action.changes).forEach(([field, value]) => {
                        handleInputChange(field, value);
                      });
                    }}
                    className="mt-2 text-xs font-medium text-purple-600 hover:text-purple-800 bg-gray-50 hover:bg-gray-100 rounded-full px-3 py-1 transition-all duration-300"
                  >
                    {suggestion.action.label} →
                  </button>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      )}
    </div>
  );
};

// Add helper function to check if form has any data
const hasFormData = (data) => {
  return Object.values(data).some(value => 
    value !== '' && 
    value !== false && 
    value !== 'none' && 
    value !== 'barMinimum' && 
    value !== 'upscale'
  );
};

export default function PricingCalculator() {
  // Initialize all costs with proper default values
  const [costs, setCosts] = useState({
    baseCost: 0,
    foodCost: 0,
    barCost: 0,
    adminFee: 0,
    gratuity: 0,
    tax: 0,
    total: 0,
    subtotal: 0,
    breakdown: {
      foodCostPerPerson: 0,
      barMinimum: 0,
      totalDrinksCost: 0,
      totalFoodCost: 0,
      baseVenueCost: 0
    }
  });

  // Initialize form data with default values
  const [formData, setFormData] = useState({
    venueRentalOnly: false,
    barPricingType: 'barMinimum',
    location: '',
    numGuests: '',
    duration: '',
    foodTier: 'none',
    liquorQuality: '',
    venueType: 'upscale',
    eventDay: ''
  });

  // Add this to your state declarations at the top
  const [previousServiceSelections, setPreviousServiceSelections] = useState({
    barPricingType: '',
    liquorQuality: '',
    foodTier: ''
  });

  // Add clear button function
  const handleClear = () => {
    setFormData({
      venueRentalOnly: false,
      barPricingType: 'barMinimum',
      location: '',
      numGuests: '',
      duration: '',
      foodTier: 'none',
      liquorQuality: '',
      venueType: 'upscale',
      eventDay: ''
    });
    setCosts({
      baseCost: 0,
      foodCost: 0,
      barCost: 0,
      adminFee: 0,
      gratuity: 0,
      tax: 0,
      total: 0,
      subtotal: 0,
      breakdown: {
        foodCostPerPerson: 0,
        barMinimum: 0,
        totalDrinksCost: 0,
        totalFoodCost: 0,
        baseVenueCost: 0
      }
    });
    localStorage.removeItem('calculatorFormData');
    localStorage.removeItem('calculatorCosts');
  };

  const calculateCost = useCallback((currentFormData) => {
    const {
      numGuests,
      duration,
      eventDay,
      location,
      barPricingType,
      liquorQuality,
      foodTier,
      venueType,
      venueRentalOnly
    } = currentFormData;

    if (!numGuests || !duration || !eventDay || !location) return {};

    const durationHours = parseInt(duration.match(/(\d+)/)[1]);
    const guestRange = getGuestRangeKey(numGuests);
    
    let adjustedVenueCost = 0;
    let barCost = 0;
    let foodCost = 0;
    const dayMultiplier = MULTIPLIERS.eventDay[eventDay] || 1.0;
    const locationMultiplier = MULTIPLIERS.location[location] || 1.0;

    if (venueRentalOnly) {
      // Venue rental only calculations
      adjustedVenueCost = VENUE_ONLY_PRICING[guestRange][durationHours];
      adjustedVenueCost *= MULTIPLIERS.venueType[venueType] || 1.0;
      adjustedVenueCost *= dayMultiplier;
      adjustedVenueCost *= locationMultiplier;
      
      // Ensure bar and food costs are zero
      barCost = 0;
      foodCost = 0;
    } else {
      // F&B service calculations
      adjustedVenueCost = 0; // No venue rental fee for F&B services
      if (barPricingType === 'barMinimum') {
        barCost = PRICING_MATRIX[guestRange][durationHours];
        barCost *= MULTIPLIERS.venueType[venueType] || 1.0;
        barCost *= dayMultiplier;
        barCost *= locationMultiplier;
      } else if (barPricingType === 'openBar' && liquorQuality) {
        const barCostPerPersonPerHour = MULTIPLIERS.barPackage[liquorQuality] || 0;
        const barScaling = BAR_SCALING[guestRange] || 1.0;
        barCost = barCostPerPersonPerHour * Number(numGuests) * durationHours * barScaling;
        barCost *= MULTIPLIERS.venueType[venueType] || 1.0;
        barCost *= dayMultiplier;
        barCost *= locationMultiplier;

        if (foodTier && foodTier !== 'none') {
          const foodCostPerPerson = MULTIPLIERS.foodTier[foodTier] || 0;
          foodCost = foodCostPerPerson * Number(numGuests);
          foodCost *= MULTIPLIERS.venueType[venueType] || 1.0;
          foodCost *= locationMultiplier;
        }
      }
    }

    // Calculate fees
    const baseCosts = {
      venue: adjustedVenueCost,
      food: foodCost,
      bar: barCost
    };

    const serviceCosts = {
      total: foodCost + barCost
    };

    const totalBaseCost = baseCosts.venue + baseCosts.food + baseCosts.bar;
    const venueFeeRate = calculateVenueFeeRate(totalBaseCost);
    const adminFee = totalBaseCost * venueFeeRate;
    const gratuity = serviceCosts.total * 0.20;
    const taxableAmount = totalBaseCost + adminFee + gratuity;
    const tax = taxableAmount * 0.08875;

    return {
      baseCost: adjustedVenueCost,
      foodCost,
      barCost,
      adminFee,
      gratuity,
      tax,
      total: taxableAmount + tax
    };
  }, []);

  // Modify the handleInputChange function to store previous selections
  const handleInputChange = (field, value) => {
    if (field === 'venueRentalOnly') {
      if (value === true) {
        // Store current selections before switching to venue rental only
        setPreviousServiceSelections({
          barPricingType: formData.barPricingType,
          liquorQuality: formData.liquorQuality,
          foodTier: formData.foodTier
        });
        // Clear service selections
        setFormData(prev => ({
          ...prev,
          venueRentalOnly: value,
          barPricingType: 'noBar',
          liquorQuality: 'none',
          foodTier: 'none'
        }));
      } else {
        // Restore previous selections when switching back
        setFormData(prev => ({
          ...prev,
          venueRentalOnly: value,
          barPricingType: previousServiceSelections.barPricingType || 'barMinimum',
          liquorQuality: previousServiceSelections.liquorQuality || 'standard',
          foodTier: previousServiceSelections.foodTier || 'appetizers'
        }));
      }
    } else {
      setFormData(prev => ({
        ...prev,
        [field]: value
      }));
    }
  };

  // Initial calculation and recalculation when form data changes
  useEffect(() => {
    if (isValidInput(formData)) {
      const newCosts = calculateCost(formData);
      setCosts(newCosts);
    }
  }, [formData, calculateCost]);

  // Add input validation function
  const isValidInput = (formData) => {
    return Boolean(
      formData.barPricingType &&
      formData.eventDay &&
      formData.location &&
      formData.numGuests &&
      formData.foodTier
    );
  };

  // Rest of the code remains the same
  const calculateVenueFeeRate = (baseTotal) => {
    for (const tier of Object.values(VENUE_FEE_TIERS)) {
      if (baseTotal <= tier.max) {
        return tier.rate;
      }
    }
    return VENUE_FEE_TIERS.tier5.rate;
  };

  const generateChartData = (costs) => {
    // Round all values to nearest $25 first
    const roundedCosts = {
      baseCost: roundTo25(costs.baseCost || 0),
      barCost: roundTo25(costs.barCost || 0),
      foodCost: roundTo25(costs.foodCost || 0),
      adminFee: roundTo25(costs.adminFee || 0),
      gratuity: roundTo25(costs.gratuity || 0),
      tax: roundTo25(costs.tax || 0),
      total: roundTo25(costs.total || 0)
    };

    const total = roundedCosts.total || 1; // Prevent division by zero
    
    const data = [
      formData.venueRentalOnly 
        ? { name: 'Venue Rental', value: roundedCosts.baseCost }
        : { name: 'Food & Beverage', value: roundedCosts.barCost + roundedCosts.foodCost },
      { name: 'Venue Fee', value: roundedCosts.adminFee },
      { name: 'Gratuity', value: roundedCosts.gratuity },
      { name: 'Taxes', value: roundedCosts.tax }
    ].filter(item => item.value > 0);

    // Add percentage to each item
    return data.map(item => ({
      ...item,
      percentage: ((item.value / total) * 100).toFixed(1)
    }));
  };

  // More vibrant and fun colors
  const CHART_COLORS = [
    '#FF6B6B',  // Coral Red
    '#4ECDC4',  // Turquoise
    '#45B7D1',  // Sky Blue
    '#96CEB4'   // Mint Green
  ];

  // Add debugging to help tune the calculations
  useEffect(() => {
    if (costs.total > 0) {
      console.log('Current total cost:', costs.total);
      console.log('Sample savings calculations:');
      console.log('35% savings:', calculateSavings(costs.total, 0.35));
      console.log('30% savings:', calculateSavings(costs.total, 0.3));
    }
  }, [costs.total]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50">
      <Navbar />
      
      <main className="max-w-7xl mx-auto px-4 py-12 grid grid-cols-1 lg:grid-cols-[400px,1fr] gap-8">
        {/* Left Column - Input Form */}
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <h1 className="text-xl font-bold text-gray-900">Event Budget Calculator</h1>
            {hasFormData(formData) && (
              <button
                onClick={handleClear}
                className="text-sm text-gray-500 hover:text-gray-700 bg-white px-3 py-1 rounded-md border border-gray-200 hover:border-gray-300 transition-colors"
              >
                Clear All
              </button>
            )}
          </div>
          
          {/* Main Form Section */}
          <div className="grid grid-cols-2 gap-4">
            {/* Core Details Section */}
            <FormControl fullWidth size="small">
              <InputLabel>Guests</InputLabel>
              <Select
                value={formData.numGuests}
                onChange={(e) => handleInputChange('numGuests', e.target.value)}
                label="Guests"
                sx={{
                  backgroundColor: 'white',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#E5E7EB',
                  }
                }}
              >
                {Object.values(GROUP_TIERS).map((tier) => (
                  <MenuItem key={tier.name} value={tier.max}>
                    {tier.max}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth size="small">
              <InputLabel>Duration</InputLabel>
              <Select
                value={formData.duration}
                onChange={(e) => handleInputChange('duration', e.target.value)}
                label="Duration"
                sx={{
                  backgroundColor: 'white',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#E5E7EB',
                  }
                }}
              >
                {DURATION_OPTIONS.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth size="small">
              <InputLabel>Day</InputLabel>
              <Select
                value={formData.eventDay}
                onChange={(e) => handleInputChange('eventDay', e.target.value)}
                label="Day"
                sx={{
                  backgroundColor: 'white',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#E5E7EB',
                  }
                }}
              >
                <MenuItem value="weekday">Weekday</MenuItem>
                <MenuItem value="weekend">Weekend</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth size="small">
              <InputLabel>Location</InputLabel>
              <Select
                value={formData.location}
                onChange={(e) => handleInputChange('location', e.target.value)}
                label="Location"
                sx={{
                  backgroundColor: 'white',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#E5E7EB',
                  }
                }}
              >
                <MenuItem value="manhattan">Manhattan</MenuItem>
                <MenuItem value="brooklyn">Brooklyn</MenuItem>
                <MenuItem value="queens">Queens</MenuItem>
              </Select>
            </FormControl>
          </div>

          {/* Venue Style Section */}
          <VenueStyleSelector formData={formData} handleInputChange={handleInputChange} />

          {/* Service Type Section */}
          <div className="mt-8">
            <ModernSwitch
              checked={formData.venueRentalOnly}
              onChange={(checked) => handleInputChange('venueRentalOnly', checked)}
              label="Venue Rental Only"
            />

            {!formData.venueRentalOnly && (
              <div className="mt-8">
                <FormControl fullWidth>
                  <InputLabel>Service Type</InputLabel>
                  <Select
                    value={formData.barPricingType}
                    onChange={(e) => handleInputChange('barPricingType', e.target.value)}
                    label="Service Type"
                    sx={{
                      backgroundColor: 'white',
                      height: '56px',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#E5E7EB',
                      }
                    }}
                  >
                    <MenuItem value="barMinimum">Food & Beverage Minimum</MenuItem>
                    <MenuItem value="openBar">Open Bar</MenuItem>
                  </Select>
                </FormControl>

                {formData.barPricingType === 'openBar' && (
                  <div className="space-y-4 mt-4">
                    <FormControl fullWidth>
                      <InputLabel>Bar Package</InputLabel>
                      <Select
                        value={formData.liquorQuality}
                        onChange={(e) => handleInputChange('liquorQuality', e.target.value)}
                        label="Bar Package"
                        sx={{
                          backgroundColor: 'white',
                          height: '56px',
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#E5E7EB',
                          }
                        }}
                      >
                        <MenuItem value="limited">
                          <div className="py-1">
                            <div>Limited (Beer & Wine)</div>
                            <div className="text-xs text-gray-500">Selection of beer and wine only</div>
                          </div>
                        </MenuItem>
                        <MenuItem value="standard">
                          <div className="py-1">
                            <div>Standard Bar</div>
                            <div className="text-xs text-gray-500">Basic spirits, beer, and wine</div>
                          </div>
                        </MenuItem>
                        <MenuItem value="premium">
                          <div className="py-1">
                            <div>Premium Bar</div>
                            <div className="text-xs text-gray-500">Top-shelf spirits, craft beer, and premium wines</div>
                          </div>
                        </MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl fullWidth>
                      <InputLabel>Food Options</InputLabel>
                      <Select
                        value={formData.foodTier}
                        onChange={(e) => handleInputChange('foodTier', e.target.value)}
                        label="Food Options"
                        sx={{
                          backgroundColor: 'white',
                          height: '56px',
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#E5E7EB',
                          }
                        }}
                      >
                        <MenuItem value="none">No Food</MenuItem>
                        <MenuItem value="appetizers">Appetizers</MenuItem>
                        <MenuItem value="buffet">Buffet</MenuItem>
                        <MenuItem value="plated">Plated Dinner</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {/* Right Column - Results */}
        <div className="bg-white rounded-xl p-6 shadow-lg border border-gray-100">
          {/* Top Row - Total and Chart side by side */}
          <div className="grid grid-cols-2 gap-6 mb-6">
            {/* Total Cost Display */}
            <div className="bg-gradient-to-r from-indigo-600 to-purple-600 rounded-lg p-4 text-white">
              <h2 className="text-base font-medium text-indigo-100 mb-1">Total Event Cost</h2>
              <div className="text-3xl font-bold">
                ${roundTo25(costs.total || 0).toLocaleString()}
              </div>
              <div className="text-xs text-indigo-200 mt-1">
                {formData.numGuests && `${formData.numGuests} guests • `}
                {formData.duration && `${formData.duration} • `}
                {formData.eventDay === 'weekend' ? 'Weekend' : 'Weekday'}
              </div>
            </div>

            {/* Per Guest Cost Card */}
            <div className="bg-gradient-to-r from-purple-50 to-pink-50 rounded-lg p-4 border border-purple-100">
              <div className="text-center space-y-2">
                <div className="text-sm text-gray-600">Cost per Guest</div>
                <div className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600">
                  ${formData.numGuests ? (
                    (roundTo25(costs.total || 0) / parseInt(formData.numGuests)).toFixed(2)
                  ) : '0.00'}
                </div>
                
                {/* Show hourly bar cost when open bar is selected */}
                {formData.barPricingType === 'openBar' && formData.liquorQuality && (
                  <div className="text-xs space-y-1 mt-1 py-1 px-2 bg-white/50 rounded-md">
                    <div className="text-purple-600 font-medium">Open Bar Rate</div>
                    <div className="flex items-center justify-center gap-1">
                      <span className="text-gray-500">
                        ${(MULTIPLIERS.barPackage[formData.liquorQuality] * 
                           MULTIPLIERS.venueType[formData.venueType] * 
                           MULTIPLIERS.location[formData.location] *
                           DURATION_SCALING[parseInt(formData.duration)] *
                           GUEST_COUNT_SCALING[formData.numGuests]).toFixed(0)}
                      </span>
                      <span className="text-gray-400">/hour</span>
                      <span className="text-gray-400">•</span>
                      <span className="text-gray-500">
                        ${(MULTIPLIERS.barPackage[formData.liquorQuality] * 
                           MULTIPLIERS.venueType[formData.venueType] * 
                           MULTIPLIERS.location[formData.location] *
                           DURATION_SCALING[parseInt(formData.duration)] *
                           GUEST_COUNT_SCALING[formData.numGuests] * 
                           parseInt(formData.duration)).toFixed(0)}
                      </span>
                      <span className="text-gray-400">/total</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Bottom Row - Chart and Breakdown side by side */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {/* Cost Breakdown Chart */}
            <div className="order-2 sm:order-1">
              <h3 className="text-sm font-medium text-gray-900 mb-2">Cost Breakdown</h3>
              <div className="h-[180px] sm:h-[200px]">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={generateChartData(costs)}
                      cx="50%"
                      cy="50%"
                      innerRadius={35}
                      outerRadius={65}
                      paddingAngle={5}
                      dataKey="value"
                    >
                      {generateChartData(costs).map((entry, index) => (
                        <Cell 
                          key={`cell-${index}`} 
                          fill={CHART_COLORS[index % CHART_COLORS.length]}
                          stroke="white"
                          strokeWidth={2}
                        />
                      ))}
                    </Pie>
                    <Tooltip 
                      formatter={(value, name, props) => [
                        `$${roundTo25(value).toLocaleString()} (${props.payload.percentage}%)`,
                        name
                      ]}
                      contentStyle={{ 
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        border: '1px solid #E5E7EB',
                        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)'
                      }}
                    />
                    <Legend 
                      verticalAlign="bottom" 
                      height={36}
                      formatter={(value) => <span className="text-xs text-gray-600">{value}</span>}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>

            {/* Detailed Cost Breakdown */}
            <div>
              <h3 className="text-sm font-medium text-gray-900 mb-2">Detailed Breakdown</h3>
              <div className="space-y-1">
                {[
                  formData.venueRentalOnly ? 
                    ['Venue Rental', roundTo25(costs.baseCost || 0)] :
                    ['Food & Beverage', roundTo25((costs.barCost + costs.foodCost) || 0)],
                  ['Venue Fee', roundTo25(costs.adminFee || 0)],
                  !formData.venueRentalOnly ? ['Gratuity', roundTo25(costs.gratuity || 0)] : null,
                  ['Sales Tax', roundTo25(costs.tax || 0)]
                ].filter(Boolean).map(([label, value]) => (
                  <div key={label} className="flex justify-between items-center p-2 rounded-md hover:bg-gray-50">
                    <span className="text-xs text-gray-600">{label}</span>
                    <span className="text-xs text-gray-900 font-medium">${value.toLocaleString()}</span>
                  </div>
                ))}
                
                <div className="pt-2 mt-2 border-t border-gray-200">
                  <div className="flex justify-between items-center p-2">
                    <span className="text-sm font-medium text-gray-900">Total</span>
                    <span className="text-sm font-bold text-purple-600">
                      ${roundTo25(costs.total || 0).toLocaleString()}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Smart Suggestions - Full Width */}
          <div className="col-span-1 sm:col-span-2">
            <SmartSuggestions 
              formData={formData} 
              costs={costs} 
              handleInputChange={handleInputChange} 
            />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
} 