/**
 * Payment Page
 * 
 * Important Notes:
 * - Handles payment initiation after contact form submission
 * - Offers Concierge service tier
 * - Integrates with Stripe for secure payment processing
 * - Validates event details from localStorage before proceeding
 * 
 * Key Features:
 * - Secure payment processing via Stripe
 * - Loading states and error handling
 * - Responsive design with modern UI
 * - Clear pricing and feature breakdown
 */

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StripeCheckout } from '../components/StripeCheckout';
import { Clock, Users, Building2, Sparkles } from 'lucide-react';
import MainLayout from '../layouts/MainLayout';
import { motion } from 'framer-motion';

const Payment = () => {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <motion.div 
        className="w-full max-w-xl mx-auto mt-16"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <div className="bg-white rounded-xl shadow-lg overflow-hidden border-2 border-gray-300 mt-16">
          <div className="pt-3 px-6">
            {/* Header & Price Section */}
            <div className="text-center">
              <h2 className="text-xl font-semibold text-gray-800 mb-0.5">Start Your Venue Search</h2>
              <p className="text-sm text-gray-500 mb-2">Let our experts find your perfect venue.</p>
            </div>

            {/* Price Section */}
            <div className="text-center mb-3">
              <div className="flex items-baseline justify-center">
                <span className="text-3xl font-bold text-gray-900">$99</span>
                <span className="ml-1.5 text-sm text-gray-600">concierge fee</span>
              </div>
              <div className="flex items-center justify-center text-green-600 text-xs">
                <svg className="w-3.5 h-3.5 mr-0.5" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                Service Satisfaction Guarantee
              </div>
            </div>

            {/* Payment Button */}
            <div className="mb-6">
              <StripeCheckout navigate={navigate} />
            </div>

            {/* Next Steps */}
            <div className="text-center mb-6">
              <p className="text-sm text-gray-600">
                Pay (Stripe) → Get Confirmation (Email) → View venue matches (days)
              </p>
            </div>

            {/* Features Grid */}
            <div className="grid grid-cols-2 gap-2 mb-3">
              <div className="flex items-center space-x-2 p-1.5 rounded-lg border border-purple-100">
                <Clock className="w-3.5 h-3.5 text-purple-600 flex-shrink-0" />
                <div className="min-w-0">
                  <p className="font-medium text-sm text-gray-900 leading-tight">Event Expert Access</p>
                  <p className="text-xs text-gray-500 leading-tight">Venue management support</p>
                </div>
              </div>

              <div className="flex items-center space-x-2 p-1.5 rounded-lg border border-purple-100">
                <Users className="w-3.5 h-3.5 text-purple-600 flex-shrink-0" />
                <div className="min-w-0">
                  <p className="font-medium text-sm text-gray-900 leading-tight">Personal Concierge</p>
                  <p className="text-xs text-gray-500 leading-tight">Best deals & outcomes</p>
                </div>
              </div>

              <div className="flex items-center space-x-2 p-1.5 rounded-lg border border-purple-100">
                <Building2 className="w-3.5 h-3.5 text-purple-600 flex-shrink-0" />
                <div className="min-w-0">
                  <p className="font-medium text-sm text-gray-900 leading-tight">Multiple Venues</p>
                  <p className="text-xs text-gray-500 leading-tight">Curated options for you in NYC</p>
                </div>
              </div>

              <div className="flex items-center space-x-2 p-1.5 rounded-lg border border-purple-100">
                <Sparkles className="w-3.5 h-3.5 text-purple-600 flex-shrink-0" />
                <div className="min-w-0">
                  <p className="font-medium text-sm text-gray-900 leading-tight">For Busy People</p>
                  <p className="text-xs text-gray-500 leading-tight">Full-service assistance</p>
                </div>
              </div>
            </div>

            {/* Guarantee Text */}
            <div className="pb-3">
              <p className="text-[10px] text-gray-500 text-center">
                *We'll handle the venue while you focus on the event. Concierge fee money-back guarantee if dissatisfied.
              </p>
            </div>
          </div>
        </div>
      </motion.div>
    </MainLayout>
  );
};

export default Payment; 