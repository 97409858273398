const logger = {
  styles: {
    info: 'color: #2563eb',
    success: 'color: #059669',
    warning: 'color: #d97706',
    error: 'color: #dc2626'
  },

  icons: {
    info: 'ℹ️',
    success: '✅',
    warning: '⚠️',
    error: '❌'
  },

  logHistory: new Set(),

  enabled: process.env.NODE_ENV === 'development',

  formatError(error) {
    return {
      name: error.name,
      message: error.message,
      stack: error.stack,
      // Add any additional error properties
      ...(error.code && { code: error.code }),
      ...(error.response && { response: error.response }),
      ...(error.config && { config: error.config })
    };
  },

  createLogKey(type, message, data) {
    return `${type}-${message}-${JSON.stringify(data)}`;
  },

  sanitizeData(data) {
    if (!data) return data;

    // If data is an array, sanitize each item
    if (Array.isArray(data)) {
      return data.map(item => this.sanitizeData(item));
    }

    // If data is an object, deep clone and sanitize
    if (typeof data === 'object' && data !== null) {
      const sanitized = { ...data };

      // List of sensitive keys to hide
      const sensitiveKeys = [
        'apiKey', '_apiKey', 'token', '_token', 'key', 'secret',
        'password', 'apiVersion', '_base', '_table', '_airtable',
        'Authorization', 'auth', 'credentials', 'baseId', 'tableId',
        'stripeKey', 'publishableKey', 'secretKey', 'priceId',
        'AIRTABLE_TOKEN', 'STRIPE_KEY', 'BLOB_TOKEN'
      ];

      // List of sensitive patterns to match
      const sensitivePatterns = [
        /pat[A-Za-z0-9]{14,}/,  // Airtable API key pattern
        /key[A-Za-z0-9_]{10,}/,  // Generic API key pattern
        /sk_[A-Za-z0-9]{24,}/,   // Stripe secret key pattern
        /pk_[A-Za-z0-9]{24,}/,   // Stripe public key pattern
        /Bearer\s+[A-Za-z0-9._-]+/i,  // Bearer tokens
        /appm[A-Za-z0-9]{14,}/,  // Airtable base ID pattern
        /tblt[A-Za-z0-9]{14,}/,  // Airtable table ID pattern
        /vercel_[A-Za-z0-9._-]+/,  // Vercel tokens
        /price_[A-Za-z0-9]{14,}/,  // Stripe price IDs
      ];

      Object.keys(sanitized).forEach(key => {
        // Check if key is sensitive
        if (sensitiveKeys.some(pattern => key.toLowerCase().includes(pattern.toLowerCase()))) {
          sanitized[key] = '[HIDDEN]';
          return;
        }

        // Check if value matches sensitive patterns
        if (typeof sanitized[key] === 'string') {
          if (sensitivePatterns.some(pattern => pattern.test(sanitized[key]))) {
            sanitized[key] = '[HIDDEN]';
            return;
          }
        }

        // Recursively sanitize nested objects
        if (typeof sanitized[key] === 'object' && sanitized[key] !== null) {
          sanitized[key] = this.sanitizeData(sanitized[key]);
        }
      });

      return sanitized;
    }

    return data;
  },

  log(type, message, data = null) {
    if (!this.enabled) return;
    
    // Sanitize data before logging
    let sanitizedData = this.sanitizeData(data);
    
    // Format error objects if present
    if (sanitizedData instanceof Error) {
      sanitizedData = this.formatError(sanitizedData);
    } else if (sanitizedData && sanitizedData.error instanceof Error) {
      sanitizedData.error = this.formatError(sanitizedData.error);
    }
    
    const logKey = this.createLogKey(type, message, sanitizedData);
    if (this.logHistory.has(logKey)) return;
    
    const logFn = type === 'error' ? console.error : console.log;
    const output = `%c${this.icons[type]} ${message}${sanitizedData ? ':' : ''}`;
    
    // Add timestamp to logs
    const timestamp = new Date().toISOString();
    const enrichedData = sanitizedData ? { timestamp, ...sanitizedData } : { timestamp };
    
    data ? logFn(output, this.styles[type], enrichedData) : logFn(output, this.styles[type]);
    
    this.logHistory.add(logKey);
    setTimeout(() => this.logHistory.delete(logKey), 1000);
  },

  group(name, type = 'info') {
    if (!this.enabled) return;
    const groupKey = this.createLogKey('group', name, type);
    if (this.logHistory.has(groupKey)) return;
    
    const timestamp = new Date().toISOString();
    console.group(`%c${this.icons[type]} ${name} (${timestamp})`, this.styles[type]);
    this.logHistory.add(groupKey);
    setTimeout(() => this.logHistory.delete(groupKey), 1000);
  },

  groupEnd() {
    if (!this.enabled) return;
    console.groupEnd();
  },

  info: function(m, d) { this.log('info', m, d) },
  success: function(m, d) { this.log('success', m, d) },
  warning: function(m, d) { this.log('warning', m, d) },
  error: function(m, d) { this.log('error', m, d) }
};

export default logger;