/**
 * SubmissionSuccess Component
 * Displays confirmation page after successful event submission
 * Features:
 * - Displays summary of submitted event details from localStorage
 * - Shows inspiration content (uploaded photo and links)
 * - Renders contact information in a readable format
 * - Uses Lucide icons for visual hierarchy
 * - Formats dates and times with dayjs
 * - Responsive layout with Tailwind CSS
 * - Handles missing/undefined values gracefully
 * - Validates and formats external URLs
 * - Animates page entry with Framer Motion
 * - Logs component lifecycle for debugging
 */

import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { 
  Calendar, Users, DollarSign, Star, Phone, Image, Mail, Clock, User, 
  PartyPopper, CheckCircle2, Building2, MessageCircle, Wine, UtensilsCrossed,
} from 'lucide-react';
import dayjs from 'dayjs';
import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';
import logger from '../utils/logger';
import { updatePaymentStatus } from '../utils/airtable';
import { toast } from 'react-hot-toast';

const humanReadable = (value) => {
  if (!value) return 'Not specified';
  if (typeof value === 'string' && value.includes('hour')) return value;
  return value.charAt(0).toUpperCase() + value.slice(1);
};

// New function to render inspiration content
const renderInspirationContent = (inspiration) => {
  const elements = [];
  
  if (inspiration?.blobUrl) {
    elements.push(
      <a 
        key="photo"
        href={inspiration.blobUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="text-violet-600 hover:text-violet-800 hover:underline"
      >
        Uploaded Photo
      </a>
    );
  }

  if (inspiration?.inspirationLink) {
    let url = inspiration.inspirationLink;
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'https://' + url;
    }

    elements.push(
      <a 
        key="link"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="text-violet-600 hover:text-violet-800 hover:underline"
      >
        {new URL(url).hostname}
      </a>
    );
  }

  if (elements.length === 0) {
    return 'Not provided';
  }

  return <div className="flex flex-col gap-2">{elements}</div>;
};

// Add a function to get the final step based on occasion
const getFinalStep = (occasion) => {
  switch (occasion?.toLowerCase()) {
    case 'business':
      return {
        title: "You're All Set! 🤝",
        description: "Get ready to host your work event"
      };
    case 'birthday':
      return {
        title: "Time to Celebrate! 🎉",
        description: "Get ready for an amazing birthday celebration"
      };
    default:
      return {
        title: "You're Ready to Go! ✨",
        description: "Get ready to host your event"
      };
  }
};

export default function SubmissionSuccess() {
  const [eventDetails, setEventDetails] = useState(null);
  
  useEffect(() => {
    // Handle payment status update if coming from payment
    const updatePaymentIfNeeded = async () => {
      const recordId = sessionStorage.getItem('stripe_record_id');
      if (recordId) {
        try {
          await updatePaymentStatus(recordId);
          localStorage.removeItem('currentStep');
          sessionStorage.removeItem('stripe_record_id');
        } catch (error) {
          console.error('Error updating payment status:', error);
          toast.error('There was an issue confirming your payment');
        }
      }
    };

    updatePaymentIfNeeded();

    // Load event details but sanitize sensitive data before logging
    const savedDetails = JSON.parse(localStorage.getItem('eventDetails') || '{}');
    
    // Create a sanitized copy of the details for logging
    const sanitizedDetails = {
      ...savedDetails,
      recordId: savedDetails.recordId ? '[HIDDEN]' : undefined,
      contact: savedDetails.contact ? {
        ...savedDetails.contact,
        email: savedDetails.contact.email ? '[HIDDEN]' : undefined,
        phone: savedDetails.contact.phone ? '[HIDDEN]' : undefined,
      } : undefined,
    };

    logger.group('Success Page');
    logger.info('Saved Details:', sanitizedDetails);
    logger.groupEnd();
    
    setEventDetails(savedDetails); // Use original details for display
  }, []);

  // Remove the early return check that was causing the issue
  // Instead, provide default values if data is missing
  const eventSummary = {
    setScene: {
      occasion: eventDetails?.setScene?.occasion || 'Not specified',
      activity: eventDetails?.setScene?.activity || 'Not specified',
      guestCount: eventDetails?.setScene?.guestCount || 'Not specified',
      budget: eventDetails?.setScene?.budget || 'Not specified',
      date: eventDetails?.setScene?.date || 'Not specified',
      startTime: eventDetails?.setScene?.startTime || 'Not specified',
      duration: eventDetails?.setScene?.duration || 'Not specified',
      foodOption: eventDetails?.setScene?.foodAndBev?.foodOption || 'Not specified',
      drinkOption: eventDetails?.setScene?.foodAndBev?.drinkOption || 'Not specified'
    },
    contact: {
      firstName: eventDetails?.contact?.firstName || '',
      lastName: eventDetails?.contact?.lastName || '',
      email: eventDetails?.contact?.email || 'Not specified',
      phone: eventDetails?.contact?.phone || 'Not specified'
    },
    inspiration: eventDetails?.inspiration || {}
  };

  // Update the nextSteps array to include icons
  const nextSteps = [
    {
      icon: MessageCircle,
      title: "Concierge Confirmation",
      description: "Your concierge is reviewing your request and will follow up with questions",
      status: "current"
    },
    {
      icon: Building2,
      title: "Venue Proposals",
      description: "Receive custom proposals for venues that match your vision + tour spaces and ask questions",
      status: "upcoming"
    },
    {
      icon: CheckCircle2,
      title: "Select Your Venue",
      description: "Choose the perfect space and we'll lock in your date",
      status: "upcoming"
    },
    {
      icon: PartyPopper,
      ...getFinalStep(eventDetails?.setScene?.occasion),
      status: "upcoming"
    }
  ];

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <div className="flex-grow p-8 pb-[80px]">
        {/* Main container with two columns and divider */}
        <div className="max-w-7xl mx-auto flex flex-col lg:flex-row">
          {/* Left column */}
          <div className="lg:w-2/3">
            <h1 className="text-[1.35rem] sm:text-[1.5rem] md:text-3xl font-bold mb-8">
              The magic has begun!{' '}
              <motion.span
                animate={{ 
                  rotate: [0, 15, -15, 0],
                  scale: [1, 1.2, 1.2, 1]
                }}
                transition={{ 
                  duration: 2,
                  repeat: Infinity,
                  repeatType: "reverse"
                }}
                className="inline-block"
              >
                ✨
              </motion.span>
              {' '}Submission Received
            </h1>
            
            {/* Payment Confirmation Banner */}
            <div className="bg-green-50 border border-green-200 rounded-lg p-4 mb-8 flex items-center">
              <CheckCircle2 className="w-5 h-5 text-green-600 mr-3 flex-shrink-0" />
              <div>
                <p className="text-green-800 font-medium">Payment Confirmed</p>
                <p className="text-green-600 text-sm">Your $99 concierge fee has been processed successfully</p>
              </div>
            </div>

            <div className="bg-white shadow-lg rounded-xl overflow-hidden border border-gray-100">
              <div className="bg-gradient-to-r from-violet-600 to-indigo-600 px-6 py-4">
                <h3 className="text-xl font-semibold text-white">Your Event Summary</h3>
              </div>
              
              <div className="p-4 grid grid-cols-2 gap-3 text-sm">
                {[
                  { icon: Star, label: "Occasion", value: humanReadable(eventSummary.setScene.occasion) },
                  { icon: PartyPopper, label: "Activity", value: humanReadable(eventSummary.setScene.activity) },
                  { icon: Users, label: "Guest Count", value: humanReadable(eventSummary.setScene.guestCount) },
                  { icon: DollarSign, label: "Budget", value: humanReadable(eventSummary.setScene.budget) },
                  { icon: Calendar, label: "Date", value: humanReadable(eventSummary.setScene.date) },
                  { icon: Clock, label: "Start Time", value: eventSummary.setScene.startTime ? 
                    dayjs(`2024-01-01T${eventSummary.setScene.startTime}`).format('h:mm A') : 'Not specified' },
                  { icon: Clock, label: "Duration", value: eventSummary.setScene.duration },
                  { icon: UtensilsCrossed, label: "Food Service", value: humanReadable(eventSummary.setScene.foodOption) },
                  { icon: Wine, label: "Drink Service", value: humanReadable(eventSummary.setScene.drinkOption) },
                  { icon: User, label: "Name", value: `${eventSummary.contact.firstName} ${eventSummary.contact.lastName}`.trim() || 'Not specified' },
                  { icon: Mail, label: "Email", value: eventSummary.contact.email },
                  { icon: Phone, label: "Phone", value: eventSummary.contact.phone },
                  { 
                    icon: Image, 
                    label: "Inspiration", 
                    value: renderInspirationContent(eventSummary.inspiration)
                  },
                ].map(({ icon: Icon, label, value, fullWidth }) => (
                  <motion.div 
                    key={label}
                    className={`flex items-start p-2 rounded-lg hover:bg-gray-50 ${fullWidth ? 'col-span-2' : ''}`}
                    whileHover={{ scale: 1.02 }}
                  >
                    <div className="bg-indigo-50 p-1.5 rounded-lg mr-2">
                      <Icon className="w-4 h-4 text-indigo-600" />
                    </div>
                    <div className="text-left min-w-0 flex-1">
                      <span className="font-medium text-gray-900">{label}:</span>
                      <div className="text-gray-600 break-words">
                        {value}
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>

          {/* Vertical Divider */}
          <div className="hidden lg:block w-px bg-gray-200 mx-8"></div>

          {/* Right column */}
          <div className="lg:w-1/3 mt-8 lg:mt-0">
            <h2 className="text-xl font-semibold mb-6">Next Steps</h2>
            {/* Timeline Steps */}
            <div className="mt-8">
              <div className="relative">
                {nextSteps.map((step, index) => (
                  <div key={step.title} className="flex items-start gap-3 relative pb-8 last:pb-0">
                    {/* Timeline line */}
                    {index !== nextSteps.length - 1 && (
                      <div className="absolute left-[17px] top-7 w-0.5 h-full bg-gray-200" />
                    )}
                    
                    {/* Status dot with icon */}
                    <div className={`rounded-full w-8 h-8 flex items-center justify-center flex-shrink-0 ${
                      step.status === 'current' 
                        ? 'bg-purple-100'
                        : 'bg-gray-100'
                    }`}>
                      <step.icon className={`w-4 h-4 ${
                        step.status === 'current'
                          ? 'text-purple-600'
                          : 'text-gray-600'
                      }`} />
                    </div>
                    
                    {/* Content */}
                    <div className="flex-1">
                      <h4 className="font-medium">{step.title}</h4>
                      <p className="text-sm text-gray-600">{step.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
