import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { PartyPopper, ArrowRight } from 'lucide-react';
import confetti from 'canvas-confetti';
import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';

export default function NotFound() {
  const [isSpinning, setIsSpinning] = useState(false);

  const shootConfetti = () => {
    setIsSpinning(true);
    
    // Shoot confetti from the center-top
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.3 },
      colors: ['#8B5CF6', '#7C3AED', '#6D28D9', '#5B21B6'],
    });

    // Reset spinning after animation
    setTimeout(() => setIsSpinning(false), 1000);
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <main className="flex-grow flex items-center justify-center bg-gradient-to-br from-gray-50 to-white px-4 py-12">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="max-w-2xl mx-auto text-center"
        >
          {/* Main Content */}
          <div className="relative mb-8">
            {/* Clickable Disco Ball */}
            <motion.button
              onClick={shootConfetti}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="w-24 h-24 mx-auto mb-6 cursor-pointer relative group"
            >
              {/* Chain (Stays Static) */}
              <div className="absolute -top-16 left-1/2 transform -translate-x-1/2 w-0.5">
                <div className="h-16 flex flex-col items-center">
                  {[...Array(8)].map((_, i) => (
                    <div 
                      key={i} 
                      className="w-2 h-2 bg-gradient-to-br from-gray-300 to-gray-400 rounded-full my-0.5"
                    />
                  ))}
                </div>
              </div>

              {/* Rotating Disco Ball */}
              <motion.div
                animate={{ 
                  rotate: isSpinning ? [0, 360] : 0,
                  scale: [1, 1.05, 1]
                }}
                transition={{ 
                  rotate: { duration: 1, ease: "linear" },
                  scale: { duration: 2, repeat: Infinity, ease: "easeInOut" }
                }}
                className="w-full h-full"
              >
                {/* Main Disco Ball */}
                <div className="w-full h-full rounded-full bg-gradient-to-br from-[#e0e0e0] via-[#f5f5f5] to-[#e0e0e0] shadow-xl overflow-hidden relative">
                  {/* Dense Mirror Pattern */}
                  <div className="absolute inset-0 grid grid-cols-12 grid-rows-12">
                    {[...Array(144)].map((_, i) => (
                      <div 
                        key={i}
                        className="border border-gray-300/20"
                        style={{
                          background: `linear-gradient(135deg, rgba(255,255,255,0.9) 0%, rgba(220,220,220,0.9) 50%, rgba(170,170,170,0.8) 100%)`,
                          animation: `discoTwinkle ${Math.random() * 1.5 + 0.5}s infinite ${Math.random() * 2}s`,
                        }}
                      />
                    ))}
                  </div>
                  
                  {/* Light Reflections */}
                  <div className="absolute inset-0">
                    <div className="absolute top-[15%] left-[25%] w-[10%] h-[10%] bg-white/90 rounded-full blur-[1px]" />
                    <div className="absolute top-[35%] right-[20%] w-[8%] h-[8%] bg-white/80 rounded-full blur-[1px]" />
                    <div className="absolute bottom-[30%] left-[40%] w-[6%] h-[6%] bg-white/70 rounded-full blur-[1px]" />
                  </div>

                  {/* Surface Shine */}
                  <div className="absolute inset-0 bg-gradient-to-br from-white/30 via-transparent to-black/10" />
                </div>
              </motion.div>
            </motion.button>

            {/* Floating Balloons */}
            <motion.div
              animate={{ y: [-10, 10] }}
              transition={{ duration: 2, repeat: Infinity, repeatType: "reverse" }}
              className="absolute top-0 left-1/4 text-2xl"
            >
              🎈
            </motion.div>
            <motion.div
              animate={{ y: [-15, 5] }}
              transition={{ duration: 2.5, repeat: Infinity, repeatType: "reverse" }}
              className="absolute top-10 right-1/4 text-2xl"
            >
              🎈
            </motion.div>
          </div>

          <h1 className="text-4xl font-bold mb-4">
            The internet dropped this page, but we won't drop the ball
            <span className="inline-block ml-2">
              <PartyPopper className="w-8 h-8 text-violet-500" />
            </span>
          </h1>

          <p className="text-gray-600 text-lg mb-8">
            This page just got lost on the dance floor. Your event dreams are safe with us!
          </p>

          {/* CTAs */}
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <motion.div
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <Link
                to="/"
                className="inline-flex items-center justify-center px-8 py-3 rounded-xl font-medium text-white bg-violet-600 hover:bg-violet-700 shadow-sm"
              >
                Choose an occasion to get started
                <ArrowRight className="ml-2 w-4 h-4" />
              </Link>
            </motion.div>
          </div>

          {/* Easter Egg */}
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1, duration: 1 }}
            className="mt-12 text-gray-400 text-sm"
          >
            Psst... click the disco ball for a surprise! ✨
          </motion.p>
        </motion.div>
      </main>
      <Footer />

      {/* Add CSS Animation for Disco Ball Reflections */}
      <style jsx>{`
        @keyframes discoTwinkle {
          0%, 100% { opacity: 0.7; }
          50% { opacity: 1; }
        }
      `}</style>
    </div>
  );
} 