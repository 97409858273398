/**
 * App Component
 * Root component handling routing and global configurations
 * Features:
 * - Protected route management
 * - Form flow testing utilities
 * - Global toast notifications
 * - localStorage cleanup on page unload
 * - Environment variable validation
 * - Development testing dashboard
 */

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import EventDetails from './pages/EventDetails';
import DreamDescription from './pages/DreamDescription';
import InspirationUpload from './pages/InspirationUpload';
import Contact from './pages/Contact';
import Payment from './pages/Payment';
import SubmissionSuccess from './pages/SubmissionSuccess';
import RouteGuard from './components/RouteGuard';
import Mission from './pages/Mission';
import { Toaster } from 'react-hot-toast';
import TestDashboard from './pages/TestDashboard';
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react"
import Calculator from './pages/Calculator';
import NotFound from './pages/NotFound';
import { Elements } from '@stripe/react-stripe-js';
import { StripeService } from './services/StripeService';

// Testing utility to track user progress through form flow
// Logs state to console at each step for debugging
export const testFlow = {
  step1: () => {
    // Validates occasion selection and localStorage persistence
    console.log('Step 1 - Occasion:', 
      JSON.parse(localStorage.getItem('eventDetails'))?.setScene?.occasion
    );
  },
  
  step2: () => {
    // After event details
    console.log('Step 2 - Event Details:', 
      JSON.parse(localStorage.getItem('eventDetails'))?.setScene
    );
  },
  
  step3: () => {
    // After dream description
    console.log('Step 3 - Dream:', 
      JSON.parse(localStorage.getItem('eventDetails'))?.dream
    );
  },
  
  step4: () => {
    // After inspiration
    console.log('Step 4 - Inspiration:', 
      JSON.parse(localStorage.getItem('eventDetails'))?.inspiration
    );
  },
  
  step5: () => {
    // After phone
    console.log('Step 5 - Phone:', 
      JSON.parse(localStorage.getItem('eventDetails'))?.phone
    );
  },
  
  final: () => {
    // Complete event details
    console.log('Final - All Details:', 
      JSON.parse(localStorage.getItem('eventDetails'))
    );
  }
};

// Ensure we're not logging environment variables
const stripePromise = StripeService.getStripePromise();

function App() {
  // Clear localStorage when user leaves/refreshes page, except for payment and success pages
  useEffect(() => {
    const handleBeforeUnload = () => {
      const currentPath = window.location.pathname;
      
      // Don't clear if on payment or success pages
      if (currentPath === '/payment' || currentPath === '/success') {
        // Only clear currentStep, keep eventDetails
        localStorage.removeItem('currentStep');
        return;
      }

      // Clear everything for other pages
      localStorage.removeItem('eventDetails');
      localStorage.removeItem('currentStep');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  return (
    <Router>
      <div className="App">
        <Toaster 
          position="top-center"
          toastOptions={{
            duration: 3000,
            style: {
              background: '#333',
              color: '#fff',
            },
            success: {
              duration: 3000,
              iconTheme: {
                primary: '#4ade80',
                secondary: '#fff',
              },
            },
            error: {
              duration: 4000,
              iconTheme: {
                primary: '#ef4444',
                secondary: '#fff',
              },
            },
          }}
        />
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/mission" element={<Mission />} />
          
          {/* Protected routes requiring previous step completion */}
          <Route path="/details" element={
            <RouteGuard requiredData="/details">
              <EventDetails />
            </RouteGuard>
          } />
          <Route path="/dream" element={<DreamDescription />} />
          <Route path="/inspiration" element={
            <RouteGuard requiredData="/inspiration">
              <InspirationUpload />
            </RouteGuard>
          } />
          <Route path="/contact" element={
            <RouteGuard requiredData="/contact">
              <Contact />
            </RouteGuard>
          } />
          <Route path="/payment" element={
            <RouteGuard requiredData="/payment">
              <Elements stripe={stripePromise}>
                <Payment />
              </Elements>
            </RouteGuard>
          } />
          <Route path="/success" element={
            <RouteGuard requiredData="/success">
              <SubmissionSuccess />
            </RouteGuard>
          } />
          <Route path="/test-dashboard" element={<TestDashboard />} />
          <Route path="/calculator" element={<Calculator />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <SpeedInsights />
        <Analytics />
      </div>
    </Router>
  );
}
export default App;

