/**
 * BackButton Component
 * 
 * A responsive navigation button that provides a way to go back to previous pages
 * Features:
 * - Shows text label on desktop (>950px) with left chevron icon
 * - Shows only larger chevron icon on mobile (<950px) 
 * - Removes "Back to" prefix from label text
 * - Hover state changes text color
 * - Positioned slightly down from top with relative positioning
 */

import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function BackButton({ to, label }) {
  const navigate = useNavigate();

  const simplifiedLabel = label.replace('Back to ', '');

  const handleBack = () => {
    navigate(-1); // Navigate to previous page
  };

  return (
    <Link 
      to={to} 
      className="inline-flex items-center text-gray-600 hover:text-gray-900 relative top-6"
      onClick={handleBack}
    >
      <div className="hidden [@media(min-width:950px)]:flex items-center">
        <ArrowLeft className="w-5 h-5 mr-2" />
        <span className="text-base">{simplifiedLabel}</span>
      </div>

      <div className="[@media(min-width:950px)]:hidden flex mr-5">
        <ArrowLeft className="w-6 h-6" strokeWidth={2.5} />
      </div>
    </Link>
  );
}