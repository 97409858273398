import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Cake, Star, Briefcase, Sparkles, PartyPopper, Building2 } from 'lucide-react';
import { motion } from 'framer-motion';
import logger from '../utils/logger';

/**
 * OccasionSelector Component
 * Allows users to select their event type
 * Features:
 * - Persistent selection state
 * - Visual feedback on selection
 * - Navigation to next step
 * - localStorage integration
 * - Responsive grid layout
 * - Toggle functionality
 */
export default function OccasionSelector() {
  const navigate = useNavigate();
  const [selectedOccasion, setSelectedOccasion] = useState(() => {
    const saved = JSON.parse(localStorage.getItem('eventDetails') || '{}');
    return saved.setScene?.occasion || null;
  });

  const occasions = [
    { 
      id: 'birthday', 
      name: 'Birthday', 
      icon: <Cake className="w-8 h-8" />,
      hoverIcon: <PartyPopper className="w-8 h-8" />
    },
    { 
      id: 'business', 
      name: 'Work', 
      icon: <Briefcase className="w-8 h-8" />,
      hoverIcon: <Building2 className="w-8 h-8" />
    },
    { 
      id: 'everything-else', 
      name: 'Everything Else', 
      icon: <Sparkles className="w-8 h-8" />,
      hoverIcon: <Star className="w-8 h-8" />
    },
  ];

  const [hoveredId, setHoveredId] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (!selectedOccasion) {
      const interval = setInterval(() => {
        setActiveIndex((current) => (current + 1) % occasions.length);
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [selectedOccasion, occasions.length]);

  const handleOccasionSelect = (occasion) => {
    if (selectedOccasion === occasion.id) {
      setSelectedOccasion(null);
      const existingDetails = JSON.parse(localStorage.getItem('eventDetails') || '{}');
      const { setScene, ...restDetails } = existingDetails;
      const { occasion, ...restSetScene } = setScene || {};
      
      logger.info('Clearing occasion selection');
      localStorage.setItem('eventDetails', JSON.stringify({
        ...restDetails,
        ...(Object.keys(restSetScene).length > 0 && { setScene: restSetScene })
      }));
    } else {
      setSelectedOccasion(occasion.id);
      const existingDetails = JSON.parse(localStorage.getItem('eventDetails') || '{}');
      const updatedDetails = {
        ...existingDetails,
        setScene: {
          ...existingDetails.setScene,
          occasion: occasion.id
        }
      };
      logger.success('Occasion selected', { occasion: occasion.id });
      localStorage.setItem('eventDetails', JSON.stringify(updatedDetails));
      navigate('/details');
    }
  };

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem('eventDetails') || '{}');
    if (savedData.setScene?.occasion) {
      setSelectedOccasion(savedData.setScene.occasion);
    }
  }, [selectedOccasion]);

  return (
    <div className="grid grid-cols-3 gap-4 max-w-2xl mx-auto md:mx-0">
      {occasions.map((occasion, index) => (
        <motion.button
          key={occasion.id}
          onClick={() => handleOccasionSelect(occasion)}
          onHoverStart={() => setHoveredId(occasion.id)}
          onHoverEnd={() => setHoveredId(null)}
          animate={!selectedOccasion ? {
            boxShadow: activeIndex === index 
              ? '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06), 0 0 0 4px rgba(147, 51, 234, 0.3)'
              : '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
            transition: {
              duration: 0.3,
              ease: "easeInOut"
            }
          } : {}}
          className={`w-full bg-white text-gray-800 font-semibold py-4 px-2 sm:px-4 rounded-lg shadow-md
            transition-all duration-200 relative hover:shadow-lg
            ${selectedOccasion === occasion.id 
              ? 'ring-2 ring-purple-500 bg-purple-50/40' 
              : 'hover:bg-purple-50/20'
            }`}
        >
          <div className="flex flex-col items-center justify-center h-full gap-2">
            <motion.div
              animate={{ 
                scale: hoveredId === occasion.id ? [1, 1.2, 1] : 1,
                rotate: hoveredId === occasion.id ? [0, 360] : 0
              }}
              transition={{ duration: 0.5 }}
              className="text-gray-700"
            >
              {hoveredId === occasion.id ? occasion.hoverIcon : occasion.icon}
            </motion.div>
            <span className="text-xs sm:text-sm md:text-base normal-case hyphens-auto">
              {occasion.name}
            </span>
          </div>
        </motion.button>
      ))}
    </div>
  );
}
