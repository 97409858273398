import React from 'react';
import { motion } from 'framer-motion';
import { Building2, PartyPopper, Clock } from 'lucide-react';
import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';

export default function Mission() {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <main className="flex-grow">
        {/* Hero Section */}
        <div className="bg-gradient-to-br from-gray-50 to-white py-6 sm:py-8">
          <div className="max-w-7xl mx-auto px-4">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="max-w-4xl"
            >
              <h1 className="text-4xl sm:text-5xl font-bold leading-tight mb-2 max-w-[600px]">
                Planning your event shouldn't feel like{' '}
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-violet-500 to-indigo-500">
                  work
                </span>
              </h1>
              <p className="text-lg text-gray-600">
              Tell us what you're looking for, and we'll match you with the perfect venue.
              </p>
            </motion.div>
          </div>
        </div>

        {/* How We Work Section */}
        <div className="max-w-7xl mx-auto px-4 py-3">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-3xl mx-auto"
          >
            <div className="bg-white rounded-lg p-6 shadow-md border border-gray-200 hover:shadow-lg transition-shadow">
              <p className="text-gray-600 mb-2 text-center text-sm">
                We believe in transparency. Here's how our pricing works:
              </p>
              <div className="flex flex-col sm:flex-row gap-3">
                {/* Concierge Service */}
                <div className="flex-1">
                  <h3 className="text-lg font-semibold mb-2 text-purple-700">Your Personal Event Concierge</h3>
                  <p className="text-gray-600 mb-2 text-sm">
                    Pay $99 for dedicated support from our expert team to find your perfect venue.
                  </p>
                  <ul className="space-y-1 text-sm text-gray-600">
                    <li className="flex items-start gap-2">
                      <span className="text-emerald-500 mt-1">✓</span>
                      <span>Personalized venue recommendations</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-emerald-500 mt-1">✓</span>
                      <span>Handle venue communication</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-emerald-500 mt-1">✓</span>
                      <span>Expert guidance throughout</span>
                    </li>
                  </ul>
                  
                  {/* Refundable Message - Show on mobile */}
                  <div className="mt-3 bg-violet-50 p-3 rounded-lg sm:hidden">
                    <p className="text-violet-900 text-sm font-medium">
                      ✨ 100% refundable if you're not satisfied with our venue options
                    </p>
                  </div>
                </div>

                {/* Vertical Divider */}
                <div className="hidden sm:block w-px bg-gray-200 mx-2"></div>

                {/* Venue Fee */}
                <div className="flex-1">
                  <h3 className="text-lg font-semibold mb-2 text-purple-700">Venue Success Fee</h3>
                  <p className="text-gray-600 mb-2 text-sm">
                    Venues pay a small percentage when bookings are made through LetsHost.
                  </p>
                  <ul className="space-y-1 text-sm text-gray-600">
                    <li className="flex items-start gap-2">
                      <span className="text-emerald-500 mt-1">✓</span>
                      <span>Pre-qualified leads</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-emerald-500 mt-1">✓</span>
                      <span>Increase brand awareness</span>
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-emerald-500 mt-1">✓</span>
                      <span>Efficient booking process</span>
                    </li>
                  </ul>
                </div>
              </div>

              {/* Refundable Message - Show on desktop */}
              <div className="mt-3 bg-violet-50 p-3 rounded-lg hidden sm:block">
                <p className="text-violet-900 text-sm font-medium">
                  ✨ 100% refundable if you're not satisfied with our venue options
                </p>
              </div>
            </div>

            {/* Human Connection Callout */}
            <div className="mt-12">
              <div className="bg-gradient-to-br from-violet-500 to-purple-600 rounded-2xl p-6 shadow-lg border border-violet-400/20">
                <div className="flex items-start gap-3">
                  <span className="text-white/90 text-xl mt-1">👋</span>
                  <div>
                    <h3 className="text-white font-medium mb-2">The Human Touch</h3>
                    <p className="text-white/90 text-base leading-relaxed">
                      Unlike venue aggregators where you're left clicking around endlessly with no response. Get matched with venues by a real human who understands your vision and is available when you need them.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Team Section */}
            <div className="mt-12">
              <h2 className="text-2xl font-bold mb-6 inline-block">
                Meet the Team
                <div className="h-1 w-1/3 bg-gradient-to-r from-violet-500 to-indigo-500 mt-1"></div>
              </h2>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Harrison Card */}
                <div className="bg-white p-4 rounded-lg shadow-md border border-gray-200 hover:shadow-lg transition-shadow">
                  <h3 className="text-lg font-semibold mb-3 text-violet-900">Harrison</h3>
                  <p className="text-gray-600">
                    As a NYC venue owner and operator, Harrison brings firsthand experience with the challenges of 
                    connecting venues with the right customers. His expertise in sales and digital marketing, combined 
                    with years of event planning experience, helps venues host more events, generate more revenue, 
                    and fill off-peak times effectively.
                  </p>
                </div>

                {/* Zach Card */}
                <div className="bg-white p-4 rounded-lg shadow-md border border-gray-200 hover:shadow-lg transition-shadow">
                  <h3 className="text-lg font-semibold mb-3 text-violet-900">Zach</h3>
                  <p className="text-gray-600">
                    A tech entrepreneur with deep roots in the event industry, Zach previously built and sold an event 
                    production company. Having managed large-scale productions, he understands how industry fragmentation 
                    creates unnecessary friction for both venues and planners. He built LetsHost to streamline 
                    the event planning process and help create memorable experiences.
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
        </div>

        {/* Feature Cards Section */}
        <div className="max-w-7xl mx-auto px-4 py-12">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {/* Time Card */}
            <div className="bg-white p-4 rounded-lg shadow-md border border-gray-200 hover:shadow-lg transition-shadow">
              <div className="flex items-center gap-3 mb-3">
                <div className="bg-violet-50 p-2 rounded-lg">
                  <Clock className="w-5 h-5 text-violet-600" />
                </div>
                <h3 className="text-xl font-semibold">15 Minutes</h3>
              </div>
              <p className="text-gray-600">Not 15 hours of searching</p>
            </div>

            {/* People Card */}
            <div className="bg-white p-4 rounded-lg shadow-md border border-gray-200 hover:shadow-lg transition-shadow">
              <div className="flex items-center gap-3 mb-3">
                <div className="bg-violet-50 p-2 rounded-lg">
                  <PartyPopper className="w-5 h-5 text-violet-600" />
                </div>
                <h3 className="text-xl font-semibold">20-200 People</h3>
              </div>
              <p className="text-gray-600">Perfect for events of all sizes</p>
            </div>

            {/* Platform Card */}
            <div className="bg-white p-4 rounded-lg shadow-md border border-gray-200 hover:shadow-lg transition-shadow">
              <div className="flex items-center gap-3 mb-3">
                <div className="bg-violet-50 p-2 rounded-lg">
                  <Building2 className="w-5 h-5 text-violet-600" />
                </div>
                <h3 className="text-xl font-semibold">One Platform</h3>
              </div>
              <p className="text-gray-600">Compare and book venues</p>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="max-w-7xl mx-auto px-4 py-12">
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <motion.a
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              href="/"
              className="inline-flex items-center justify-center px-8 py-3 rounded-xl font-medium text-white bg-violet-600 hover:bg-violet-700 shadow-sm"
            >
              Get Started
            </motion.a>
            <motion.a
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              href="mailto:alex@letshostnow.com"
              className="inline-flex items-center justify-center px-8 py-3 rounded-xl font-medium text-violet-600 bg-violet-50 hover:bg-violet-100 border border-violet-200"
            >
              Contact Us
            </motion.a>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
