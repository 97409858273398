import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { StripeService } from '../services/StripeService';

const StripeCheckout = ({ navigate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 3;
  const RETRY_DELAY = 15000; // 15 seconds

  const handlePayment = async () => {
    if (isLoading) return;
    setIsLoading(true);
    
    try {
      const eventDetails = JSON.parse(localStorage.getItem('eventDetails') || '{}');
      if (!eventDetails.recordId) {
        toast.error('Please complete the contact form first');
        navigate('/contact');
        return;
      }

      const result = await StripeService.redirectToCheckout(process.env.REACT_APP_STRIPE_CONCIERGE_PRICE_ID);
      
      if (result?.error) {
        throw new Error(result.error.message || 'Failed to open Stripe');
      }
    } catch (error) {
      console.error('Payment error:', error);
      
      if (retryCount < MAX_RETRIES) {
        setRetryCount(prev => prev + 1);
        const retryNumber = retryCount + 1;
        const loadingToast = toast.loading(
          `Failed to open Stripe. Retrying in ${RETRY_DELAY/1000} seconds... (Attempt ${retryNumber}/${MAX_RETRIES})`
        );
        
        // Wait for RETRY_DELAY milliseconds
        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
        toast.dismiss(loadingToast);
        
        // Retry the payment
        handlePayment();
      } else {
        toast.error('Unable to open Stripe. Please refresh the page and try again, or contact support.');
        setRetryCount(0);
        setIsLoading(false);
      }
      return;
    }
    
    setIsLoading(false);
  };

  return (
    <button
      onClick={handlePayment}
      disabled={isLoading}
      className="w-full bg-[#2563EB] hover:bg-[#1E40AF] text-white py-3 px-4 rounded-lg font-medium transition-colors"
    >
      {isLoading ? 'Opening Stripe...' : 'Proceed to Payment'}
    </button>
  );
};

export { StripeCheckout };